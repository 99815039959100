import large1 from './LLB_8731.jpg'
import large2 from './LLB_8740.jpg'
import large3 from './LLB_8754.jpg'
import large4 from './LLB_8759.jpg'
import large5 from './LLB_8761.jpg'
import large6 from './LLB_8763.jpg'
import large7 from './LLB_8778.jpg'
import large8 from './LLB_8792.jpg'
import large9 from './LLB_8803.jpg'
import large10 from './LLB_8828.jpg'
import large11 from './LLB_8851.jpg'
import large12 from './MLW_HR001.jpg'
import large13 from './MLW_HR008.jpg'
import large14 from './MLW_HR010.jpg'
import large15 from './MLW_HR012.jpg'
import large16 from './MLW_HR015.jpg'
import large17 from './MLW_HR017.jpg'
import large18 from './MLW_HR028.jpg'
import large19 from './MLW_HR030.jpg'
import large20 from './MLW_HR031.jpg'
import large21 from './MLW_HR033.jpg'
import large22 from './MLW_HR035.jpg'
import large23 from './MLW_HR039.jpg'

export const larges= [
  large1,
  large2,
  large3,
  large4,
  large5,
  large6,
  large7,
  large8,
  large9,
  large10,
  large11,
  large12,
  large13,
  large14,
  large15,
  large16,
  large17,
  large18,
  large19,
  large20,
  large21,
  large22,
  large23
]