import thumb1 from './LLB_8731.png'
import thumb2 from './LLB_8740.png'
import thumb3 from './LLB_8754.png'
import thumb4 from './LLB_8759.png'
import thumb5 from './LLB_8761.png'
import thumb6 from './LLB_8763.png'
import thumb7 from './LLB_8778.png'
import thumb8 from './LLB_8792.png'
import thumb9 from './LLB_8803.png'
import thumb10 from './LLB_8828.png'
import thumb11 from './LLB_8851.png'
import thumb12 from './MLW_HR001.png'
import thumb13 from './MLW_HR008.png'
import thumb14 from './MLW_HR010.png'
import thumb15 from './MLW_HR012.png'
import thumb16 from './MLW_HR015.png'
import thumb17 from './MLW_HR017.png'
import thumb18 from './MLW_HR028.png'
import thumb19 from './MLW_HR030.png'
import thumb20 from './MLW_HR031.png'
import thumb21 from './MLW_HR033.png'
import thumb22 from './MLW_HR035.png'
import thumb23 from './MLW_HR039.png'

export const thumbs = [
  thumb1,
  thumb2,
  thumb3,
  thumb4,
  thumb5,
  thumb6,
  thumb7,
  thumb8,
  thumb9,
  thumb10,
  thumb11,
  thumb12,
  thumb13,
  thumb14,
  thumb15,
  thumb16,
  thumb17,
  thumb18,
  thumb19,
  thumb20,
  thumb21,
  thumb22,
  thumb23
]