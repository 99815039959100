import React, {useEffect, useState} from "react";
import {
  mainOrange,
  mainWhite,
  montserrat,
  screen,
  ccbiffbamboomRegular, darkBlue, montserratRegular, transHover,
  innerWidth
} from "../components/common/variables";
import styled from "styled-components";
import heroBg from '../images/home/martin-lysicrates-walk-v2-hero.png'
import heroBgMobile from '../images/home/martin-lysicrates-walk-v2-hero-mb.png'
import heroThumbMobile from '../images/home/martin-lysicrates-walk-2023.jpg'
import contentBg from '../images/home/content-bg.png'
import galleryBg from '../images/home/gallery-bg.png'
import thumbnailArticleFirst from '../images/home/article-first__thumb.webp'
import thumbnailArticleSecond from '../images/home/article-second__thumb.png'
import buttonBg from '../images/home/button-bg.png'
import Layout from "../layout";
import {larges} from "../images/Large";
import {thumbs} from "../images/Thumbs";
import Carousel, {Modal, ModalGateway} from "react-images";

const Wrapper = styled.div`
  font-family: ${montserrat};
  background: ${mainWhite};
  position: relative;
  @media ${screen.medium} {
    display: block;
  }

  .flex-nav {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    background: ${mainWhite};
    padding: 15px 0 0 0;

    &__brand {
      padding-bottom: 22px;

      h1 {
        color: ${mainOrange};
        font-size: 1.6rem;
        font-weight: 900;
        line-height: 1;
        @media ${screen.large} {
          font-size: 1.8rem;
        }

        .text-light {
          font-weight: 200;

          &--sm {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
  
  .home__banner {
    position: relative;
    @media ${screen.medium} {
      background-image: url(${heroBg});
      background-position: center;
      background-size: cover;
    }
    
    .thumb-mobile {
      width: 100%;
      @media ${screen.medium} {
        position: absolute;
        top:0;
        right: 0;
        max-width: 47%;
        height: 100%;
      }
    }
    
    .text-banner {
      max-width: 100%;
      background-image: url(${heroBgMobile});
      padding: 175px 6% 200px 12%;
      @media ${screen.medium} {
        max-width: 53%;
        background-image: none;
      }
      color: white;
      font-family: ${ccbiffbamboomRegular};
      display: flex;
      flex-direction: column;
      gap: 30px;
    }
    
    .text-banner__heading {
      white-space: pre-wrap;
      text-align: left;
      @media ${screen.small} {
        font-size: 3.2rem;
        width: auto;
        margin: 0;
        max-width: none;
        text-align: left;
        line-height: 1.4;
      }
      font-weight: 400;
      font-size: 1.6rem;
      margin: 0 auto;
      width: 100%;
    } 
    
    .text-banner__day {
      font-size: 2.2rem;
      width: auto;
      margin: 0;
      max-width: none;
      line-height: 1.4;
      color: #fe8000;
    }
    
    .text-banner__description {
      white-space: pre-wrap;
      font-family: ${montserrat};
      font-weight: 700;
    }
  }

  .home__content {
    background-color: #ece8ef;
  }

  .bg-home__content {
    background-image: url(${contentBg});
    background-position: center;
    background-size: cover;
    padding: 4rem 6% 0 12%;
  }

  .article__wrap {
    display: flex;
    gap: 60px;
    margin-bottom: 5%;
    align-items: center;
    flex-direction: column;
    &.article-first {
      flex-direction: column-reverse;
      @media ${screen.medium} {
        flex-direction: row;
      }
    }
    @media ${screen.medium} {
      flex-direction: row;
    }
    .article__content {
      font-family: ${montserrat};
      width: 100%;
      color: #162345;
      line-height: 1.4;
      letter-spacing: 1px;
      font-weight: 600;
      @media ${screen.medium} {
        width: 50%;
      }
      p {
        font-size: inherit;
        margin-bottom: 5%;
      }
      .button-rotate {
        background-image: url(${buttonBg});
        background-size: 100% 100%;
        margin-left: -5%;
        display: flex;
        justify-content: center;
        .button {
          padding: 1% 4%;
          letter-spacing: 2px;
          color: #FFF;
          font-style: italic;
          font-weight: 700;
        }
      }
    }

    .article__thumbnail {
      width: 100%;
      @media ${screen.medium} {
        width: 50%;
      }
      .thumbnail {
        width: 100%;
        border-radius: 5px;
      }
      
      .caption {
        letter-spacing: 1px;
        color: #162345;
        font-weight: 600;
        text-align: center;
      }
    }
  }
  
  .article-second {
    margin-bottom: 0;
  }

  .gallery-wrapper {
    padding: 50px 0 32px 0;
    background-image: url(${galleryBg});
    background-size: 101%;
    background-position-x: -2px;
    @media ${screen.small} {
      padding: 55px 0 32px 0;
    }
    @media ${screen.large} {
      padding: 4rem 4% 8rem 10%;
    }

    .gallery {
      max-width: ${innerWidth};
      margin: 0 auto;
      padding-left: 30px;
      padding-right: 30px;
      @media ${screen.small} {
        padding-left: 50px;
        padding-right: 50px;
      }

      &__heading {
        color: ${mainOrange};
        font-family: ${ccbiffbamboomRegular};
        font-size: 1.9rem;
        white-space: pre;
        @media ${screen.small} {
          font-size: 4.5rem;
        }

        .break {
          display: block;
          margin-left: 22px;
          @media ${screen.small} {
            margin-left: 50px;
          }
        }
      }

      &__subheading {
        color: ${darkBlue};
        font-family: ${montserratRegular};
        font-size: 1.05rem;
        margin-top: 14px;
        margin-bottom: 30px;
        white-space: pre;
        font-weight: 700;
        font-style: italic;
        margin-left: 22px;
        @media ${screen.small} {
          font-size: 1.25rem;
          margin-top: 22px;
          margin-left: 100px;
        }
      }

      &__list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -7px;
        @media ${screen.large} {
          margin: 0 -17px;
        }

        li {
          width: calc(25% - 14px);
          margin: 7px;
          transition: ${transHover};
          @media ${screen.small} {
            width: calc(16.66% - 14px);
          }
          @media ${screen.large} {
            width: calc(12.5% - 34px);
            margin: 17px;
          }

          img {
            cursor: pointer;
            width: 100%;
            display: block;
            border: 4px ${mainWhite} solid;
            @media ${screen.small} {
              border: 8px ${mainWhite} solid;
            }
          }

          &:hover {
            opacity: 0.58;
          }
        }
      }

      &__more-btn {
        margin-top: 16px;
        display: flex;
        justify-content: flex-end;

        button {
          background: ${darkBlue};
          border: 0;
          border-radius: 0;
          cursor: pointer;
          font-size: 1.25rem;
          color: ${mainWhite};
          font-family: ${ccbiffbamboomRegular};
          transition: ${transHover};
          outline-color: ${mainOrange};
          padding: 11px 19px;
          @media ${screen.small} {
            font-size: 1.5rem;
            margin-top: 24px;
            padding: 13px 20px;
          }
          @media ${screen.withCursor} {
            &:hover {
              background: ${mainOrange};
            }
          }
        }
      }
    }
  }
`

const TheMartinLysicratesPriceV2 = () => {
  const [scale, setScale] = useState(1)
  const [imgListLength, setImgListLength] = useState(16);
  const [isModalOpen, setModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentSnaps, setCurrentSnaps] = useState([{ src: "" }]);

  useEffect(() => {
    if (!window) return

    const resize = () => {
      setScale(window.innerWidth / 3300 > 1 ? 1 : window.innerWidth / 3300)
    }
    resize()
    window.addEventListener('resize', resize)
    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [])

  const openImgModal = (index, images) => {
    console.log('i')
    setModal(true);
    setCurrentSnaps(images);
    setCurrentIndex(index);
  };

  const closeImgModal = () => {
    setModal(false);
    setCurrentIndex(false);
  };

  const expandGalleryList = () => {
    setImgListLength(imgListLength + 16);
  };

  return (
    <Layout>
      <Wrapper>
        <div className="home__banner">
          <div className="text-banner">
            <h1 className="text-banner__heading" style={{fontSize: `${ 20 + 82 * scale}px`}}>{'James Martin\nChildren\'s Walk'}</h1>
            <p className='text-banner__day' style={{fontSize: `${ 8 + 48 * scale}px`}}>Wednesday 29th November 2023</p>
            <p className="text-banner__description" style={{fontSize: `${ 10 + 30 * scale}px`}}>{'James Martin - The Boy\nHis story demonstrates the power of determination and education'}</p>
          </div>
          <img className={'thumb-mobile'} src={heroThumbMobile}  alt={''}/>
        </div>
        <div className="home__content">
          <div className="bg-home__content">
            <div className="article__wrap article-first">
              <div className="article__content" style={{fontSize: `${ 10 + 30 * scale}px`}}>
                <p>Born in Ireland in 1820, James Martin's life took a significant turn when his father accepted a job as a horse groom for the Governor of New South Wales.</p>
                <p>James Martin's potential was evident from a young age, but his location in Parramatta limited his options to continue his education. With no high school in the area, he had to consider several schools in Sydney. Despite the challenges, he was determined to attend high school and walked 13 miles to get there.</p>
                <div className="button-rotate">
                  <div className="button" style={{fontSize: `${ 70 * scale}px`}}>The James Martin Children’s Walk – Celebrating 175 years of Public Education in NSW. </div>
                </div>

              </div>
              <div className="article__thumbnail">
                <img className="thumbnail" alt={''} src={thumbnailArticleFirst} />
                <p className="caption" style={{fontSize: `${ 8 + 22 * scale}px`}}>James Martin Premier of new south Wales</p>
              </div>
            </div>
            <div className="article__wrap article-second">
              <div className="article__thumbnail">
                <img className="thumbnail" alt={''} src={thumbnailArticleSecond} />
                <p className="caption" style={{fontSize: `${ 8 + 22 * scale}px`}}>James Martin as he walked the 13 miles to school!</p>
              </div>
              <div className="article__content" style={{fontSize: `${ 10 + 30 * scale}px`}}>
                <p>While still in school, he started writing articles for The Australian and at the age of 19, he became the Acting Editor of the publication. G R Nichols, a well-known lawyer and editor, served as his mentor and profoundly impacted his life.</p>
                <p>At the age of 28, he realised that he could venture into fields beyond journalism and law, which led him to pursue a career in politics. His political career began as the Attorney General of New South Wales, and in 1863, he was elected as the Premier of New South Wales for the first time. He was able to secure two more terms as Premier, with his final term ending in 1872.</p>
                <p>The James Martin Children's Walk is a 25km symbolic walk from Parramatta Square to Martin Place. It took place on Wednesday 29 November 2023.</p>
                <p>Students were encouraged to join some or all six legs of the walk that leads to Martin Place.</p>
              </div>
            </div>
          </div>
          <div className="gallery-wrapper">
            <div className="gallery">
              <h2 className="gallery__heading">
                A FEW PICS <span className="break">FROM THE DAY!</span>
              </h2>
              <p className="gallery__subheading">
                Take a look at some of the photos
                <br />
                taken from this incredible day!
              </p>

              <ul className="gallery__list">
                {thumbs.slice(0, imgListLength).map((img, i) => (
                  <li
                    key={i}
                    onClick={() =>
                      openImgModal(
                        i,
                        // change array of image with src property
                        larges.map(photo => {
                          return {
                            src: photo,
                          };
                        })
                      )
                    }
                  >
                    <div>
                      {img && (
                        <img
                          src={img}
                          alt="gallery"
                        />
                      )}
                    </div>
                  </li>
                ))}
              </ul>
              {larges.length - 1 >= imgListLength && (
                <div className="gallery__more-btn">
                  <button onClick={expandGalleryList}>Load more</button>
                </div>
              )}
            </div>
          </div>
        </div>
      </Wrapper>
      <ModalGateway>
        {isModalOpen && (
          <Modal onClose={closeImgModal}>
            <Carousel views={currentSnaps} currentIndex={currentIndex} />
          </Modal>
        )}
      </ModalGateway>
    </Layout>
  )
}

export default TheMartinLysicratesPriceV2;
